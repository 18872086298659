import React from 'react';
import { useTranslation } from 'react-i18next';
import './LanguageSwitcher.css';
import { useNavigate, useParams } from 'react-router-dom';


const LanguageSwitcher = () => {
    const { i18n } = useTranslation();
    const navigate = useNavigate();
    const { lang } = useParams(); // Get current language from URL

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
        if (lng !== lang) {
            i18n.changeLanguage(lng); // Change i18n language
            navigate(`/${lng}${window.location.pathname.substring(3)}`); // Update URL
        }
    };

    const currentLanguage = i18n.language;

    return (
        <div className="language-switcher">
            <button
                className={`language-button ${currentLanguage === 'en' ? 'active' : ''}`}
                onClick={() => changeLanguage('en')}
            >
                en
            </button>
            |
            <button
                className={`language-button ${currentLanguage === 'pt' ? 'active' : ''}`}
                onClick={() => changeLanguage('pt')}
            >
                pt
            </button>
        </div>
    );
};

export default LanguageSwitcher;
const faqs = [
    {
        category: "Utilização dos terminais",
        questions: [
            {
                question: "Qual o montante máximo que posso comprar como utilizador registado?",
                answer: (
                    <>
                        <p>Para utilizadores registados, os limites são os seguintes:</p>
                        <ul>
                            <li>995€ por compra</li>
                            <li>2000€ por hora</li>
                            <li>2900€ por dia</li>
                            <li>9000€ por mês</li>
                            <li>18000€ por trimestre</li>
                        </ul>
                        <p>
                            Efetue o registo <a target="_blank" href="/registo" rel="noreferrer">no nosso portal.</a>
                        </p>
                    </>
                ),
            },
            {
                question: "Qual o montante máximo que posso comprar como utilizador não registado?",
                answer: (
                    <>
                        <p>Para utilizadores não registados, aplica-se o seguinte limite:</p>
                        <ul>
                            <li>950€ total acumulado</li>
                        </ul>
                        <p>
                            Efetue o registo <a target="_blank" href="/registo" rel="noreferrer">no nosso portal.</a>
                        </p>
                    </>
                ),
            },
            {
                question: "Qual o montante mínimo que posso comprar?",
                answer: "O montante mínimo de compra é de 5€.",
            },
            {
                question: "Ajuda! Ocorreu um erro/problema durante a compra! O que devo fazer?",
                answer: (
                    <p>
                        Muito raramente ocorrem problemas, mas não se preocupe! Os terminais são monitorizados em tempo real e o
                        operador enviará as moedas manualmente para a sua carteira o mais depressa possível.
                    </p>
                ),
            },
            {
                question: "Posso vender moeda virtual nas máquinas da Mind the Coin?",
                answer: (
                    <p>
                        As máquinas da Mind the Coin apenas suportam a compra de moeda virtual. Vendas serão suportadas em breve
                        (acompanhe as nossas redes sociais para saber quando!).
                    </p>
                ),
            },
            {
                question: "O que acontece se o terminal ficar sem acesso à Internet ou se houver um corte de energia durante a compra?",
                answer: (
                    <p>
                        A moeda virtual será enviada automaticamente assim que o terminal tenha o acesso restabelecido. Se tal não acontecer
                        em 15 minutos, por favor envie um email para <a href="mailto:suporte@mindthecoin.com">suporte@mindthecoin.com</a>
                        {' '}ou utilize o formulário de contacto neste site. Por favor, indique o montante, data e hora na mensagem.
                    </p>
                ),
            },
            {
                question: "Posso comprar Bitcoin com o meu cartão Multibanco (Crédito ou Débito)?",
                answer: (
                    <>
                        <p>
                            Neste momento, os terminais apenas aceitam pagamentos em numerário. No entanto, estamos ativamente a preparar os nossos equipamentos para suportarem pagamentos com cartão.
                        </p>
                        <p>
                            Fique atento às nossas redes sociais para saber quando esta funcionalidade estará disponível!
                        </p>
                    </>
                ),
            }
        ]
    },
    {
        category: "Processamento de transações",
        questions: [
            {
                question: "Como sei que a minha transação foi bem-sucedida?",
                answer: (
                    <>
                        <p>Após a compra, a transação será exibida no recibo do terminal e pode ser verificada na blockchain.</p>
                        <p>Utilize um explorador de blocos, como o <a href="https://blockchair.com" target="_blank" rel="noreferrer">Blockchair</a>, para acompanhar o estado da transação.</p>
                    </>
                ),
            },
            {
                question: "Fiz uma compra num dos vossos terminais mas ainda não recebi a transação. O que se passa?",
                answer: (
                    <p>
                        As transações são sempre lançadas para a blockchain no ato da compra, contudo as primeiras confirmações
                        podem demorar algum tempo. Em média, para Bitcoin, a primeira confirmação demora cerca de 10 minutos,
                        mas pode demorar várias horas, dependendo do tamanho da <i>memory pool</i> e de outras métricas relativas
                        à mineração. Consulte um explorador de blocos (exemplo: <a target="_blank" href="https://blockchair.com" rel="noreferrer">blockchair</a>) para obter uma estimativa de tempo até à primeira confirmação.
                    </p>
                ),
            },
        ],
    },
    {
        category: "Regulamentação",
        questions: [
            {
                question: "O uso de Bitcoin é legal em Portugal?",
                answer: "Sim, a compra, venda e utilização de Bitcoin e Litecoin é perfeitamente legal em Portugal. Não obstante, nos termos da Lei, as empresas que desenvolvem atividades com ativos virtuais estão sujeitas a supervisão regulatória.",
            },
            {
                question: "Tenho de declarar as minhas transações com ativos virtuais ao Fisco?",
                answer: "Dependendo da sua situação fiscal específica, pode ser necessário declarar transações ou ganhos relacionados com ativos virtuais. Consulte um contabilista ou especialista fiscal da sua confiança.",
            },
            {
                question: "A Mind the Coin é supervisionada por alguma entidade pública?",
                answer: "Sim, a Mind the Coin está registada junto do Banco de Portugal como uma entidade que presta serviços com ativos virtuais, em conformidade com a legislação aplicável.",
            },
            {
                question: "Porque motivo é necessário fornecer dados pessoais para comprar Bitcoin?",
                answer: "Os dados pessoais são recolhidos para cumprir os requisitos legais em matéria de prevenção do branqueamento de capitais e do financiamento do terrorismo.",
            },
        ],
    },
    {
        category: "Segurança e Privacidade",
        questions: [
            {
                question: "O terminal armazena os meus dados pessoais?",
                answer: "Não, os terminais não armazenam quaisquer dados pessoais. Os dados são armazenados num servidor remoto e seguro, garantindo assim a sua privacidade e a segurança dos seus dados.",
            },
            {
                question: "Como posso proteger a minha carteira de moeda virtual?",
                answer: (
                    <ul>
                        <li>Utilize uma carteira com boa reputação, como Ledger, Trezor, Green Wallet ou qualquer outra <i>wallet</i> confiável.</li>
                        <li>Ative a autenticação de dois fatores (2FA) sempre que possível.</li>
                        <li>Nunca partilhe a sua chave privada (ou cópias de segurança) com ninguém.</li>
                    </ul>
                ),
            },
            {
                question: "Posso enviar bitcoin para a plataforma de \"investimento\" XYZ...",
                answer: (
                    <p>
                        Não, trata-se quase certamente de uma burla. <b>POR MAIS CREDÍVEL QUE LHE PAREÇA O SITE, JAMAIS</b> envie
                        bitcoin ou outros ativos virtuais para plataformas de investimento sem fazer o mínimo de investigação primeiro.
                    </p>
                ),
            },
        ],
    },

    {
        category: "Registo",
        questions: [

            {
                question: "Que documentos são necessários para o registo?",
                answer: (
                    <p>
                        Não é necessário apresentar nenhum documento, apenas precisa de se autenticar no nosso <a href="/registo" target="_blank" rel="noreferrer">portal</a> com a sua Chave Móvel Digital (CMD).
                    </p>
                ),
            },
            {
                question: "Quanto tempo demora o registo a ser aprovado?",
                answer: (
                    <p>
                        O registo é aprovado num prazo máximo de 2 horas.
                    </p>
                ),
            },
            {
                question: "Como posso obter a minha Chave Móvel Digital (CMD)?",
                answer: (
                    <>
                        <p>
                            A Chave Móvel Digital (CMD) é um método simples e seguro de autenticação que é utilizado por diversos serviços. Siga os passos abaixo para obter a sua CMD:
                        </p>
                        <ol>
                            <li>
                                Aceda ao site oficial em <a href="https://www.autenticacao.gov.pt" target="_blank" rel="noreferrer">autenticacao.gov.pt</a>.
                            </li>
                            <li>Selecione a opção <b>Ativar Chave Móvel Digital</b>.</li>
                            <li>
                                Escolha uma das formas de ativação apresentadas.
                            </li>
                            <li>Defina o seu número de telemóvel e o PIN da CMD.</li>
                            <li>Confirme o processo com o código enviado para o seu telemóvel.</li>
                        </ol>
                        <p>
                            Para mais informações, consulte o site oficial:{" "}
                            <a href="https://www.autenticacao.gov.pt" target="_blank" rel="noreferrer">
                                autenticacao.gov.pt
                            </a>.
                        </p>
                    </>
                ),
            },
        ],
    },


    {
        category: "Outros",
        questions: [

            {
                question: "Posso comprar uma fração de moeda virtual?",
                answer: "Sim, praticamente todas as moedas virtuais permitem que compre frações.",
            },
            {
                question: "Perdi o acesso à minha carteira onde armazenava a moeda virtual que comprei no terminal. Podem ajudar-me?",
                answer: (
                    <span>
                        Infelizmente não há nada que possamos fazer para o ajudar uma vez que não temos qualquer controlo sobre as
                        transações já publicadas na Blockchain.
                    </span>
                ),
            },
            {
                question: "Quando é que a Mind the Coin vai suportar a moeda virtual X?",
                answer: (
                    <p>
                        A Mind the Coin apenas opera com moedas virtuais de elevada reputação e comunidade de programadores ativa. Se a
                        moeda desejada preencher esses requisitos, envie um email para <a href="mailto:suporte@mindthecoin.com">suporte@mindthecoin.com</a>
                        &nbsp; com o seu pedido.
                    </p>
                ),
            },
        ],
    },

];

export default faqs;
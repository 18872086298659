import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './Index.css'; // Ensure your CSS file is still included
import './i18n'; // Import i18n configuration
import { HelmetProvider } from "react-helmet-async";


ReactDOM.render(
  <React.StrictMode>
    <HelmetProvider>
    <App />
    </HelmetProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
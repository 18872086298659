import React from "react";
import "./LocationCard.css"; // Import the CSS file
import StatusIndicator from "../../utils/StatusIndicator";
import { useTranslation } from 'react-i18next';

const LocationText = ({ selectedLocation }) => {
  const { t } = useTranslation('location');

  if (!selectedLocation) {
    return <p>{t('location.nolocationselected')}</p>;
  }

  return (
    <div className="card">
      <div className="card-content">
        <span className="card-title">{selectedLocation.location}</span>
        <p>{selectedLocation.address.join(", ")}</p>
        <p>
          {Array.isArray(selectedLocation.openingTimes) ? (
            selectedLocation.openingTimes.map((time, index) => (
              <React.Fragment key={index}>
                <i>{time}</i>
                {index < selectedLocation.openingTimes.length - 1 && <br />}
              </React.Fragment>
            ))
          ) : (
            <i>{selectedLocation.openingTimes}</i>
          )}
        </p>
      </div>

      <div className="card-action">
        <div className="row">
          <div className="col s4" style={{marginRight: '1rem'}}>
            <a
              href={selectedLocation.infoLink}
              target="_blank"
              rel="noopener noreferrer"
              className="icon-description"
            >
              <i className="material-icons text-primary-light">info</i>
              <span className='text-primary-light'>{t('location.information')}</span>
            </a>
          </div>

          <div className="col s4">
            <a
              href={`https://www.google.com/maps/search/?api=1&map_action=map&query=${selectedLocation.pin[0]}%2C${selectedLocation.pin[1]}`}
              target="_blank"
              rel="noopener noreferrer"
              className="icon-description"
            >
              <i className="material-icons text-primary-light">map</i>
              <span className='text-primary-light'>{t('location.map')}</span>
            </a>
          </div>

          <div className="col s4">
            <div className="icon-description">
              <StatusIndicator status={selectedLocation.status} />
              <span className='text-primary-light'>{t('location.status')}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LocationText;

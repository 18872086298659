import React, { useState, useEffect, useRef, useCallback } from 'react';
import M from 'materialize-css';
import 'materialize-css/dist/css/materialize.min.css';
import { useTranslation } from 'react-i18next';
import './Stepper.css';

const Stepper = ({ steps }) => {
  const [currentStep, setCurrentStep] = useState(0);
  const stepRefs = useRef([]);

  const { t } = useTranslation('instrucoes');

  useEffect(() => {
    const elems = document.querySelectorAll('.collapsible');
    const instances = M.Collapsible.init(elems, { accordion: true });
    instances.forEach((instance, index) => {
      if (index === currentStep) {
        instance.open();
      } else {
        instance.close();
      }
    });
    return () => {
      instances.forEach((instance) => instance.destroy());
    };
  }, [currentStep]);

  useEffect(() => {
    if (stepRefs.current[currentStep]) {
      stepRefs.current[currentStep].scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  });

  // Memoized functions
  const handleNext = useCallback(() => {
    setCurrentStep(prevStep => (prevStep < steps.length - 1 ? prevStep + 1 : prevStep));
  }, [steps.length]);

  const handlePrev = useCallback(() => {
    setCurrentStep(prevStep => (prevStep > 0 ? prevStep - 1 : prevStep));
  }, []);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "ArrowRight") {
        handleNext();
      } else if (event.key === "ArrowLeft") {
        handlePrev();
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleNext, handlePrev]);

  const handleStepClick = (index) => {
    const instance = M.Collapsible.getInstance(stepRefs.current[index].parentElement);
    if (index === currentStep) {
      instance.close(index);
      setCurrentStep(-1); // Set to -1 to indicate no step is active
    } else {
      instance.open(index);
      setCurrentStep(index);
    }
  };

  return (
    <div className='stepper-container'>
      <div className='row'>
        <div className='col s12 m8 l9'>
          <ul className='collapsible'>
            {steps.map((step, index) => (
              <li
                key={index}
                ref={(el) => (stepRefs.current[index] = el)}
                className={index === currentStep ? 'active' : ''}
                onClick={() => handleStepClick(index)}
              >
                <div className='collapsible-header'>
                  {step.title}
                  <span className='badge'>
                    {t('instrucoes.step')} {index + 1}
                  </span>
                </div>
                <div
                  className='collapsible-body'
                  style={{ display: index === currentStep ? 'block' : 'none' }}
                >
                  <span className='secondary-text'>{step.content}</span>
                  <div className="stepper-nav-buttons">
                    <button
                      className="btn-floating btn-large waves-effect waves-light text-primary"
                      onClick={(e) => { e.stopPropagation(); handlePrev(); }}
                      disabled={currentStep === 0}
                    >
                      <i className="material-icons">chevron_left</i>
                    </button>
                    <button
                      className="btn-floating btn-large waves-effect waves-light red"
                      onClick={(e) => { e.stopPropagation(); handleNext(); }}
                      disabled={currentStep >= steps.length - 1}
                    >
                      <i className="material-icons">chevron_right</i>
                    </button>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <p className='center-align'>
        {t('instrucoes.step')} {currentStep + 1} {t('instrucoes.of')} {steps.length}
      </p>
    </div>
  );
};

export default Stepper;
import React from 'react';

const SocialNetworks = () => {
    return (
        <>
        <div className="social-networks">
            <a className="blue-text text-darken-2" target="_blank" href="https://www.linkedin.com/company/mind-the-coin/" rel="noreferrer"><i className="fab fa-linkedin-in fa-1x"></i>&emsp; </a>
            <a className="blue-text text-darken-2" target="_blank" href="https://www.facebook.com/Mind-the-Coin-803235803215553/" rel="noreferrer"><i className="fab fa-facebook-f fa-1x"></i>&emsp; </a>
            <a className="blue-text text-darken-2" target="_blank" href="https://twitter.com/mindthecoin" rel="noreferrer"><i className="fab fa-twitter fa-1x"></i>&emsp; </a>
            <a className="blue-text text-darken-2" target="_blank" href="https://instagram.com/mindthecoin" rel="noreferrer"><i className="fab fa-instagram fa-1x"></i>&emsp; </a>
            <a className="blue-text text-darken-2" target="_blank" href="https://www.reddit.com/user/mindthecoin" rel="noreferrer"><i className="fab fa-reddit fa-1x"></i>&emsp; </a>
            <a className="blue-text text-darken-2" target="_blank" href="https://medium.com/@mindthecoin" rel="noreferrer"><i className="fab fa-medium fa-1x"></i> </a>
            </div>
        </>
    );
};

export default SocialNetworks;

// filepath: /home/fernando.guimaraes/Documents/mindthecoin/website-react/src/pages/Instrucoes.js
import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import './Instrucoes.css';
import { Trans, useTranslation } from 'react-i18next';
import M from 'materialize-css';
import Stepper from '../components/Stepper';

const Instrucoes = () => {
  const { t } = useTranslation('instrucoes');
  const { i18n } = useTranslation();
  const { lang } = useParams();
  const currentLang = lang || i18n.resolvedLanguage; // Default to detected language

  useEffect(() => {
    M.AutoInit();
  }, []);


  const steps = Array.from({ length: 10 }, (_, i) => ({
    title: t(`instrucoes.step${i + 1}_title`),
    content: (
      <>
        <img
          src={`/assets/images/instructions/${i18n.resolvedLanguage}/${i + 1}.png`}
          alt={t(`step${i + 1}_alt`)}
          className="responsive-image"
        />
        <p className="text-dark">{t(`instrucoes.step${i + 1}_p1`)}</p>
        {t(`instrucoes.step${i + 1}_p2`) !== `instrucoes.step${i + 1}_p2` && (
          <p>
            <Trans
              t={t}
              i18nKey={`instrucoes.step${i + 1}_p2`}
              components={{
                a: <a href="/registo" target="_blank" rel="noreferrer" aria-label="Registo" />,
                b: <b />,
                u: <u />
              }}
            />
          </p>
        )}
      </>
    )
  }));

  return (
    <section className='container'>
          <div id="instructions" className="section scrollspy">
            <h1 className="yellow-text text-darken-2">{t('instrucoes.instructions_title')}</h1>
            <p>
              <b>{t('instrucoes.before_start_title')}</b>

            <Trans
              t={t}
              i18nKey={`instrucoes.before_start_p1`}
              components={{
                a: <Link to={currentLang === 'en' ? `/${currentLang}/instructions/wallet` : `/${currentLang}/instrucoes/carteira`}>{t('carteira.instructions')}</Link>,
                b: <b />,
                u: <u />
              }} />
              </p>

            <p>{t('instrucoes.instructions_intro')}</p>
            <Stepper steps={steps} />
          </div>
    </section>
  );
};

export default Instrucoes;
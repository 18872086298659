import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import navbar_en from './locales/en/navbar.json';
import warning_en from './locales/en/warning.json';
import index_en from './locales/en/index.json';
import caution_en from './locales/en/caution.json';
import location_en from './locales/en/location.json';
import footer_en from './locales/en/footer.json';
import cookies_en from './locales/en/cookies.json';
import privacidade_en from './locales/en/privacidade.json';
import faqs_en from './locales/en/faqs.json';
import informacoes_en from './locales/en/informacoes.json';
import instrucoes_en from './locales/en/instrucoes.json';

import navbar_pt from './locales/pt/navbar.json';
import warning_pt from './locales/pt/warning.json';
import index_pt from './locales/pt/index.json';
import caution_pt from './locales/pt/caution.json';
import location_pt from './locales/pt/location.json';
import footer_pt from './locales/pt/footer.json';
import cookies_pt from './locales/pt/cookies.json';
import privacidade_pt from './locales/pt/privacidade.json';
import faqs_pt from './locales/pt/faqs.json';
import informacoes_pt from './locales/pt/informacoes.json';
import instrucoes_pt from './locales/pt/instrucoes.json';


i18n
    .use(LanguageDetector) // Automatically detect language
    .use(initReactI18next) // Passes i18n instance to React

    .init({
        detection: {
            order: ['path', 'localStorage', 'navigator'],
            lookupFromPathIndex: 0,  // Detects language from `/en` in URL
            lookupLocalStorage: 'i18nextLng'
        },
        supportedLngs: ['en', 'pt'], // Only allow 'en' and 'pt'
        load: 'languageOnly',
        nonExplicitSupportedLngs: true, // This ensures 'en-GB' or 'en-US' defaults to 'en'
        resources: {
            en: {
                navbar: navbar_en,
                warning: warning_en,
                index: index_en,
                caution: caution_en,
                location: location_en,
                footer: footer_en,
                cookies: cookies_en,
                privacidade: privacidade_en,
                faqs: faqs_en,
                informacoes: informacoes_en,
                instrucoes: instrucoes_en
            },
            pt: {
                navbar: navbar_pt,
                warning: warning_pt,
                index: index_pt,
                caution: caution_pt,
                location: location_pt,
                footer: footer_pt,
                cookies: cookies_pt,
                privacidade: privacidade_pt,
                faqs: faqs_pt,
                informacoes: informacoes_pt,
                instrucoes: instrucoes_pt
            },
        },
        ns: ['navbar', 'warning', 'index', 'caution', 'location', 'footer', 'cookies', 'privacidade', 'faqs'], // Define namespaces
        fallbackLng: 'pt', // Default language
        interpolation: {
            escapeValue: false,
        },
    });

export default i18n;
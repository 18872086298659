import React from 'react'
import CryptoCalculator from '../components/Mempool/MempoolCalculator' // Import the CryptoCalculator component

const CryptoTools = () => {
  return (
    <div className='section'>
      <div className='container'>
        <div className='row'>
          <div className='col s12 m6 l12'>
            <CryptoCalculator />
          </div>
          <div className='col s12 m6 l8'>
            <h4 className='primary-text'>Bitcoin</h4>
            <p>
              É uma moeda digital descentralizada, sem banco central
              ou administrador único, que pode ser enviada de utilizador para
              utilizador na rede peer-to-peer do Bitcoin sem necessidade de
              intermediários.
            </p>
            <p>
              As transações são verificadas pelos nós da rede através de
              criptografia e registadas num registo público distribuído chamado
              blockchain.
            </p>
            <p>
              O Bitcoin foi inventado em 2008 por uma pessoa ou grupo de pessoas
              desconhecidas sob o nome de Satoshi Nakamoto e começou em 2009,
              quando a sua implementação foi lançada como software de código
              aberto.
            </p>
            <p>
              Os Bitcoins são criados como recompensa por um processo conhecido
              como mineração. Podem ser trocados por outras moedas, produtos e
              serviços.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CryptoTools

import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

const Caution = () => {
    const { t } = useTranslation('caution');

    return (
        <>
            <section className="container home-regular">
                <div className="row">
                    <div className="col s12 offset-m2 m8 center-align">
                        <div className="icon-block">
                            <h2 className="text-primary-light">
                                <i className="yellow-text text-darken-2 fa fa-exclamation-triangle"></i>
                                 {t('caution.title')}
                            </h2>
                            <p className="light">{t('caution.instructions')}</p>
                            <p className="light">{t('caution.transactions_irreversible')}</p>
                            <p className="light">{t('caution.wallet_backup')}</p>
                            <p className="light">
                                <Trans t={t}
                                    i18nKey="caution.scam_alert"
                                    components={{ bold: <b /> }}
                                />
                            </p>
                            <p className="light">
                                <Trans t={t}
                                    i18nKey="caution.volatility_warning"
                                    components={{ bold: <b /> }}
                                />
                            </p>
                            <p className="light">{t('caution.contact_support')}</p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Caution;
// filepath: /home/fernando.guimaraes/Documents/mindthecoin/website-react/src/pages/Instrucoes.js
import React, { useEffect } from 'react';
import './Instrucoes.css';
import { Trans, useTranslation } from 'react-i18next';
import M from 'materialize-css';
import Stepper from '../components/Stepper';
import { Link, useParams } from 'react-router-dom';

const InstrucoesCarteira = () => {
  const { t } = useTranslation('instrucoes');
  const { i18n } = useTranslation();
  const { lang } = useParams();
  const currentLang = lang || i18n.resolvedLanguage; // Default to detected language

  useEffect(() => {
    M.AutoInit();
  }, []);


  const steps = Array.from({ length: 12 }, (_, i) => ({
    title: t(`carteira.step${i + 1}_title`),
    content: (
      <>
        <img
          src={`/assets/images/wallet/${i + 1}.png`}
          alt={t(`step${i + 1}_alt`)}
          className="responsive-wallet-image"
        />
        <p className="text-dark">
          <Trans
              t={t}
              i18nKey={`carteira.step${i + 1}_p1`}
              components={{
                b: <b />,
                i: <i />,
                u: <u />
              }}
          />
        </p>
        {t(`carteira.step${i + 1}_p2`) !== `carteira.step${i + 1}_p2` && (
          <p>
            <Trans
              t={t}
              i18nKey={`carteira.step${i + 1}_p2`}
              components={{
                b: <b />,
                i: <i />,
                u: <u />,
                a:  <Link to={currentLang === 'en' ? `/${currentLang}/instructions` : `/${currentLang}/instrucoes`}></Link>,
              }}
            />
          </p>
        )}
      </>
    )
  }));

  return (
    <section className='container'>
          <div id="instructions" className="section scrollspy">
            <h1 className="yellow-text text-darken-2">{t('carteira.title')}</h1>
            <p>{t('carteira.intro')}</p>
            <Trans
              t={t}
              i18nKey={`carteira.intro2`}
              components={{
                b: <b />,
                u: <u />,
              }}
            />
            <p>
            <Trans
              t={t}
              i18nKey={`carteira.intro3`}
              components={{
                b: <b />,
                u: <u />,            
              }}
            />
            </p>
            <p>
            <Link  target="_blank" rel="noopener noreferrer" to="https://apps.apple.com/us/app/green-bitcoin-wallet/id1402243590">{t(`carteira.ios_link`)}</Link>
            </p>
            <p>
            <Link  target="_blank" rel="noopener noreferrer" to="https://play.google.com/store/apps/details?id=com.greenaddress.greenbits_android_wallet">{t(`carteira.android_link`)}</Link>
            </p>
            <Stepper steps={steps} />
          </div>
    </section>
  );
};

export default InstrucoesCarteira;

import React from 'react';
import { useTranslation } from 'react-i18next';

import SocialNetworks from './SocialNetworks';
import './Footer.css'

import { useParams, Link } from 'react-router-dom';



const Footer = () => {
  const { t } = useTranslation('footer');
  const { i18n } = useTranslation();

  const { lang } = useParams();
  const currentLang = lang || i18n.resolvedLanguage; // Default to detected language

    return (
        <>
        <footer id="sobre" className="page-footer blue-grey darken-3">
          <div className="container home-regular">
            <div className="row">
              <div className="col l12 s12 center-align">
                <h5 className="white-text">{t('footer.title')}</h5>

                <div className="contactscontainer">
                  <div className="espaco">
                    <h6>{t('footer.contact_investment_title')}</h6>
                    <p><a className="white-text" href="#!"><i className="icon-spacing fas fa-envelope fa-1x yellow-text text-darken-2"></i>
                      {t('footer.contact_investment_address')}</a></p>
                  </div>

                  <div className="suporte">
                    <h6>{t('footer.contact_support_title')}</h6>
                    <p><a className="white-text" href="#!"><i className="icon-spacing fas fa-envelope fa-1x yellow-text text-darken-2"></i>
                      {t('footer.contact_support_address')}</a></p>
                </div>

                <div className="imprensa">
                    <h6>{t('footer.contact_press_title')}</h6>
                    <p><a className="white-text" href="#!"><i className="icon-spacing fas fa-envelope fa-1x yellow-text text-darken-2"></i>
                      {t('footer.contact_press_address')}</a></p>
                </div>

              </div>
                <SocialNetworks/>
            </div>
          </div>
        </div>
        <div className="footer-copyright blue-grey darken-4">
          <div className="container center-align">
              <div>{t('footer.registered')}</div>
            <div>
                {t('footer.copyright')}
            </div>
            <div className="">

                <Link className="grey-text" to={currentLang === 'en' ? `/${currentLang}/privacy` : `/${currentLang}/privacidade`}>{t('footer.privacy')}</Link>
                {' '}–{' '}
                <Link className="grey-text" to={currentLang === 'en' ? `/${currentLang}/terms` : `/${currentLang}/termos`}>{t('footer.terms')}</Link>
                {' '}–{' '}
                <Link className="grey-text" to={currentLang === 'en' ? `/${currentLang}/cookies` : `/${currentLang}/cookies`}>{t('footer.cookies')}</Link>
                {' '}-{' '}
                <a rel="noopener noreferrer" target="_blank" href="https://www.livroreclamacoes.pt" className="grey-text">{t('footer.complaints')}</a>
            </div>
          </div>
        </div>
      </footer>
        </>
    );
};

export default Footer;

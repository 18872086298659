import React from 'react';
import Warning from '../components/Warning';
import Location from '../components/Locations';
import Caution from '../components/Caution';

import { Trans, useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';

import '../Index.css';
import '../assets/css/materialize.css';
import '../assets/css/fontawesome.css';
import '../assets/css/fa-brands.css';
import '../assets/css/fa-regular.css';
import '../assets/css/fa-solid.css';


const Index = () => {

  const { t, i18n } = useTranslation('index');
  const { lang } = useParams();

  const currentLang = lang || i18n.resolvedLanguage; // Default to detected language

  return (
    <>
      <Warning />

      <header className="home-banner">
        <div className="content">
          <div className="content-item">
            {t('index.title')}
          </div>

          <div className="content-item">
            {t('index.subtitle')}
          </div>

          <div className="content-item-images">
            <img className="content-item-image" src="/assets/images/bitcoin.png" alt="Bitcoin" />
            <img className="content-item-image" src="/assets/images/litecoin.png" alt="Litecoin" />
          </div>
        </div>
      </header>

      <section className="home-grey-section">
        <Location />
      </section >

      <section className="container home-emphasis">
        <div className="row">
          <div className="col s12 m8 offset-m2 center-align">
            <div className="icon-block">
              <h2 className="text-primary-light">
                <i className="yellow-text text-darken-2 fas fa-universal-access">
                </i> {t('index.section2_title')}
              </h2>
              <p>{t('index.section2_p1')}</p>

              <p className="light">
                <Trans t={t}
                  i18nKey="index.section2_p2"
                  components={{
                    a: <Link to={currentLang === 'en' ? `/${currentLang}/instructions` : `/${currentLang}/instrucoes`}>{t('navbar.instructions')}</Link>
                  }}
                />
              </p>

              <p className="light">
                <Trans t={t}
                  i18nKey="index.section2_p3"
                  components={{
                    a: <a href="/registo" target="_blank" rel="noreferrer" aria-label="Registo" />
                  }}
                />
              </p>


            </div>
          </div>
        </div>
      </section>

      <section className="home-grey-section">
        <div className="row">
          <div className="col s12 offset-m3 m6 center-align">
            <div className="icon-block">
              <h2 className="text-primary-light">
                <i className="yellow-text text-darken-2 fa fa-trophy"></i>  {t('index.section3_title')}</h2>
              <p className="light">
                <Trans t={t}
                  i18nKey="index.section3_p1"
                  components={{ bold: <b /> }}
                />
              </p>
              <p className="light">
                <Trans t={t}
                  i18nKey="index.section3_p2"
                  components={{ bold: <b /> }}
                />
              </p>
              <p className="light">
                <Trans t={t}
                  i18nKey="index.section3_p3"
                  components={{ bold: <b /> }}
                />
              </p>

            </div>
          </div>
        </div>
      </section>


              <Caution />


    </>
  );
};

export default Index;

import React, { useState } from 'react';
import LocationMap from './LocationMap';
import LocationText from './LocationText';
import SocialNetworks from '../SocialNetworks';
import { Trans, useTranslation } from 'react-i18next';

const Location = () => {
  const [selectedLocation, setSelectedLocation] = useState(null);

  const { t } = useTranslation('location');

  return (
    <>
    <div style={{width: '100%', marginTop: '2rem' }}>

        <div className="container center-align p-2">
          <h2 className="text-primary-light"><i style={{ color: '#e6af2e' }} className="fas fa-map-pin" ></i> {t('location.title')} </h2>
        </div>
      <div  style={{ width: '100%', marginBottom: '1rem' }} mb={4}>
        <LocationMap selectedLocation={selectedLocation} onSelectLocation={setSelectedLocation} />
      </div>

        <div><LocationText /></div>

      <div className='center-align'>
        <p>
            <Trans t={t}
              i18nKey="location.note"
              components={{ bold: <b /> }}
            />
        </p>
        <SocialNetworks color="blue" />
      </div>
    </div>
    </>
  );
};

export default Location;
const locations_en = [
  {
    id: "map-alverca",
    pin: [38.8904648, -9.0399861],
    city: "Alverca",
    location: "Sociedade Filarmónica Recreio Alverquense",
    status: "online",
    address: ["Praça da Sociedade Filarmónica Recreio Alverquense", "2615-391"],
    openingTimes: ["Monday to Saturday: 10:00 AM to 7:00 PM"],
    infoLink:
      "https://coinatmradar.com/bitcoin_atm/32584/bitcoin-atm-general-bytes-alverca-do-ribatejo-sociedade-filarmonica-recreio-alverquense/"
  },
  {
    id: "map-braga",
    pin: [41.5608, -8.3859],
    city: "Braga",
    location: "Yah Atlântico Business Center",
    status: "online",
    address: [
      "Variante do Fojo - Rua Padre Carmelitas",
      "Yeah Atlântico Building, 4719-005"
    ],
    openingTimes: [
      "Monday to Friday: 9:00 AM to 6:00 PM"
    ],
    infoLink:
      "https://coinatmradar.com/bitcoin_atm/56122/bitcoin-atm-general-bytes-braga-yeah-atlantico/"
  },
  {
    id: "map-caldas",
    pin: [39.4101244, -9.1401931],
    city: "Caldas da Rainha",
    location: "Washstation - Self-Service Laundry",
    status: "online",
    address: ["R. José Filipe Neto Rebelo 2A", "2500-222 Caldas da Rainha"],
    openingTimes: ["Every day: 7:00 AM to 9:00 PM"],
    infoLink:
      "https://coinatmradar.com/bitcoin_atm/63354/bitcoin-atm-general-bytes-caldas-da-rainha-wash-station-lavandaria/"
  },
  {
    id: "map-coimbra",
    pin: [40.22912480838475, -8.438774726046569],
    city: "Coimbra",
    location: "Washstation - Self-Service Laundry",
    status: "online",
    address: [
      "Largo dos Sindicatos, Loreto Urb. 1 - Store 8",
      "3025-395 Coimbra"
    ],
    openingTimes: ["Every day: 8:00 AM to 10:00 PM"],
    infoLink:
      "https://coinatmradar.com/bitcoin_atm/63971/bitcoin-atm-general-bytes-coimbra-wash-station-lavandaria/"
  },
  {
    id: "map-faro",
    pin: [37.0288821, -7.945],
    city: "Faro",
    location: "Forum Algarve",
    status: "online",
    address: ["8009-020"],
    openingTimes: ["Monday to Sunday: 10:00 AM to 11:00 PM"],
    infoLink:
      "https://coinatmradar.com/bitcoin_atm/35267/bitcoin-atm-general-bytes-faro-forum-algarve/"
  },
  {
    id: "map-funchal",
    pin: [32.6513518, -16.905181],
    city: "Funchal",
    location: "IPRO Clinic / Mais Clinic",
    status: "online",
    address: ["Rua João de Deus, 12B", "9050-027 Funchal"],
    openingTimes: ["Monday to Saturday: 9:00 AM to 8:00 PM"],
    infoLink:
      "https://coinatmradar.com/bitcoin_atm/79247/bitcoin-atm-general-bytes-funchal-mais-clinic/"
  },
  {
    id: "map-gaia",
    pin: [41.13612084, -8.60850771],
    city: "Gaia",
    location: "Power Dot Charging Station - Jardim do Morro",
    status: "online",
    address: ["Rua Rocha Leão 320", "Gaia, 4430-198"],
    openingTimes: ["Monday to Sunday: 9:00 AM to 8:00 PM"],
    infoLink:
      "https://coinatmradar.com/bitcoin_atm/46528/bitcoin-atm-general-bytes-vila-nova-de-gaia-power-dot-charging-station/"
  },
  {
    id: "map-lisboa",
    pin: [38.72253347583836, -9.133582014684423],
    city: "Lisbon",
    location: "Cotton Club Laundry and Café",
    status: "online",
    address: ["Rua Andrade 15A", "Lisbon, 1170-014"],
    openingTimes: ["Monday to Sunday: 8:00 AM to 9:00 PM"],
    infoLink:
      "https://coinatmradar.com/bitcoin_atm/45145/bitcoin-atm-general-bytes-lisbon-cotton-club-laundry-and-cafe/"
  },
  {
    id: "map-maia",
    pin: [41.259, -8.641],
    city: "Maia",
    location: "TecMaia",
    status: "online",
    address: [
      "Maia Science and Technology Park",
      "Rua Eng.º Frederico Ulrich 2650, 4470-605"
    ],
    openingTimes: ["Monday to Saturday: 10:00 AM to 7:00 PM"],
    infoLink:
      "https://coinatmradar.com/bitcoin_atm/8962/bitcoin-atm-general-bytes-maia-tecmaia/"
  },
  {
    id: "map-porto",
    pin: [41.14895102696872, -8.608638369338237],
    city: "Porto",
    location: "French Fries Factory",
    status: "online",
    address: ["Rua do Bonjardim 302 Porto, 4000-115"],
    openingTimes: ["Tuesday to Saturday: 12:00 PM to 11:00 PM"],
    infoLink:
      "https://coinatmradar.com/bitcoin_atm/88331/bitcoin-atm-general-bytes-porto-french-fries-factory/"
  }
];

export default locations_en;

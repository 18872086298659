// LocationMap.js with InfoWindows
import React, { useState } from 'react';
import { APIProvider, Map, AdvancedMarker, InfoWindow } from '@vis.gl/react-google-maps';
import locations_pt from '../../assets/js/locations_pt';
import locations_en from '../../assets/js/locations_en';
import mapMarker from '../../assets/images/maps/map-marker.svg';
import LocationCard from './LocationCard';
import { useTranslation } from 'react-i18next';

const mapContainerStyle = {
  width: '100%',
  height: '500px',
};

const defaultZoom = 6.5;

const center = {
  lat: 39.561715,
  lng: -7.7,
};

const LocationMap = () => {

  const [selectedLocation, setSelectedLocation] = useState(null);
  const { i18n } = useTranslation();
  const isEnglish = i18n.language === 'en';
  let locations;
  if (isEnglish) { locations = locations_en; } else { locations = locations_pt; }

  return (
    <>
      <APIProvider
        // apiKey={''}
        apiKey={'AIzaSyBBMCH8NHLIGqZv2MCfQh7zz2JTOK707bI'}
        onLoad={() => console.log('Maps API has loaded.')}
        region='pt'
      >
        <Map
          defaultZoom={defaultZoom}
          defaultCenter={center}
          style={mapContainerStyle}
          disableDefaultUI={true}
          mapId='642d857bda883b4d'
        >
          {locations.map((location) => (
            <AdvancedMarker
              key={location.id}
              position={{
                lat: location.pin[0],
                lng: location.pin[1],
              }}
              onClick={() => setSelectedLocation(location)} // Set the clicked location
            >
              <img alt="Ponto de venda" src={mapMarker} width={40} height={40} />

            </AdvancedMarker>
          ))}

          {/* Render InfoWindow if a location is selected */}
          {selectedLocation && (
            <InfoWindow headerContent={selectedLocation.city} 
              position={{
                lat: selectedLocation.pin[0],
                lng: selectedLocation.pin[1],
              }}
              onCloseClick={() => setSelectedLocation(null)} // Close InfoWindow on click
            >
              <div style={{ marginTop: '-0.6rem' }}>
                <LocationCard selectedLocation={selectedLocation} />
              </div>
            
            </InfoWindow>
          )}
        </Map>
      </APIProvider>
    </>
  );
};

export default LocationMap;

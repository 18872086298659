import React, { useEffect, useState } from 'react';
import M from 'materialize-css';
import './faq.css';
import { useTranslation } from 'react-i18next';

import faqs_en from '../assets/js/faqs_en';
import faqs_pt from '../assets/js/faqs_pt';
import './Informacoes.css';


const Faq = () => {

  const { i18n } = useTranslation();
  const { t } = useTranslation('faqs');

  const isEnglish = i18n.resolvedLanguage === 'en';
  let faqs;
  if (isEnglish) { faqs = faqs_en; } else { faqs = faqs_pt; }



  const [search, setSearch] = useState("");

  // Filter FAQs based on the search input
  const filteredFaqs = faqs.filter(section =>
    section.questions.some(faq =>
      faq.question.toLowerCase().includes(search.toLowerCase())
    )
  );

  useEffect(() => {
    M.Collapsible.init(document.querySelectorAll('.collapsible'));
  }, [filteredFaqs]);

  return (
    <section className='container'>
      <div className='section scrollspy'>
      <h1 className="text-primary">{t('faqs.title')}</h1>
      <div className="input-field">
        <input
          id="search-faqs"
          type="text"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
        <label htmlFor="search-faqs" className="active" style={{ color: '#212121' }}>
          {t('faqs.search')}
        </label>
      </div>

      {filteredFaqs.map((section, index) => (
        <div key={index} className="section">
          <h5 className="secondary-text">{section.category}</h5>
          {section.questions
            .filter(faq => faq.question.toLowerCase().includes(search.toLowerCase()))
            .map((faq, idx) => (
              <ul className="collapsible" key={idx}>
                <li>
                  <div className="collapsible-header">
                    <i className="material-icons">expand_more</i>
                    {faq.question}
                  </div>
                  <div className="collapsible-body">
                    <span>{faq.answer}</span>
                  </div>
                </li>
              </ul>
            ))}
        </div>
      ))}
      </div>
    </section>
  );
};

export default Faq;

import React from 'react';
import { useTranslation } from 'react-i18next';

const Cookies = () => {
    const { t } = useTranslation('cookies');

    return (
        <>
            <div className='section'>
                <div className='container'>

                    <h2>{t('cookies.title')}</h2>

                    <p>
                        {t('cookies.description')}
                    </p>
                </div>
            </div>




        </>



    );
};

export default Cookies;

const faqs = [
    {
        category: "Using the Terminals",
        questions: [
            {
                question: "What is the maximum amount I can buy as a registered user?",
                answer: (
                    <>
                        <p>For registered users, the limits are as follows:</p>
                        <ul>
                            <li>€995 per purchase</li>
                            <li>€2000 per hour</li>
                            <li>€2900 per day</li>
                            <li>€9000 per month</li>
                            <li>€18000 per quarter</li>
                        </ul>
                        <p>
                            Register <a target="_blank" href="/registo" rel="noreferrer">on our portal.</a>
                        </p>
                    </>
                ),
            },
            {
                question: "What is the maximum amount I can buy as an unregistered user?",
                answer: (
                    <>
                        <p>For unregistered users, the following limit applies:</p>
                        <ul>
                            <li>€950 total accumulated</li>
                        </ul>
                        <p>
                            Register <a target="_blank" href="/registo" rel="noreferrer">on our portal.</a>
                        </p>
                    </>
                ),
            },
            {
                question: "What is the minimum amount I can buy?",
                answer: "The minimum purchase amount is €5.",
            },
            {
                question: "Help! An error/problem occurred during the purchase! What should I do?",
                answer: (
                    <p>
                        Problems rarely occur, but don’t worry! The terminals are monitored in real time, and the operator will send the coins manually to your wallet as soon as possible.
                    </p>
                ),
            },
            {
                question: "Can I sell virtual currency at Mind the Coin machines?",
                answer: (
                    <p>
                        Mind the Coin machines only support buying virtual currency. Sales will be supported soon (follow us on social media to know when!).
                    </p>
                ),
            },
            {
                question: "What happens if the terminal loses Internet access or there is a power outage during the purchase?",
                answer: (
                    <p>
                        The virtual currency will be sent automatically as soon as the terminal regains access. If this does not happen within 15 minutes, please send an email to <a href="mailto:suporte@mindthecoin.com">suporte@mindthecoin.com</a>
                        {' '}or use the contact form on this site. Please include the amount, date, and time in your message.
                    </p>
                ),
            },
            {
                question: "Can I buy Bitcoin with my Multibanco (Credit or Debit card)?",
                answer: (
                    <>
                        <p>
                            At the moment, the terminals only accept cash payments. However, we are actively preparing our equipment to support card payments.
                        </p>
                        <p>
                            Follow us on social media to know when this feature will be available!
                        </p>
                    </>
                ),
            }
        ]
    },
    {
        category: "Transaction Processing",
        questions: [
            {
                question: "How do I know if my transaction was successful?",
                answer: (
                    <>
                        <p>After the purchase, the transaction will be displayed on the terminal receipt and can be verified on the blockchain.</p>
                        <p>Use a block explorer, such as <a href="https://blockchair.com" target="_blank" rel="noreferrer">Blockchair</a>, to track the transaction status.</p>
                    </>
                ),
            },
            {
                question: "I made a purchase at one of your terminals but haven’t received the transaction yet. What’s going on?",
                answer: (
                    <p>
                        Transactions are always launched onto the blockchain at the time of purchase, but the first confirmations may take some time. On average, for Bitcoin, the first confirmation takes about 10 minutes, but it can take several hours depending on the size of the <i>memory pool</i> and other mining-related metrics. Check a block explorer (e.g., <a target="_blank" href="https://blockchair.com" rel="noreferrer">Blockchair</a>) for an estimated time until the first confirmation.
                    </p>
                ),
            },
        ],
    },
    {
        category: "Regulation",
        questions: [
            {
                question: "Is the use of Bitcoin legal in Portugal?",
                answer: "Yes, buying, selling, and using Bitcoin and Litecoin is perfectly legal in Portugal. However, under the law, companies engaged in activities with virtual assets are subject to regulatory supervision.",
            },
            {
                question: "Do I need to declare my virtual asset transactions to the tax authorities?",
                answer: "Depending on your specific tax situation, it may be necessary to declare transactions or gains related to virtual assets. Consult a trusted accountant or tax specialist.",
            },
            {
                question: "Is Mind the Coin supervised by any public entity?",
                answer: "Yes, Mind the Coin is registered with the Bank of Portugal as an entity providing services with virtual assets, in compliance with applicable legislation.",
            },
            {
                question: "Why is it necessary to provide personal data to buy Bitcoin?",
                answer: "Personal data is collected to meet legal requirements regarding anti-money laundering and counter-terrorist financing.",
            },
        ],
    },
    {
        category: "Security and Privacy",
        questions: [
            {
                question: "Does the terminal store my personal data?",
                answer: "No, the terminals do not store any personal data. The data is stored on a remote and secure server, ensuring your privacy and data security.",
            },
            {
                question: "How can I protect my virtual currency wallet?",
                answer: (
                    <ul>
                        <li>Use a reputable wallet, such as Ledger, Trezor, Green Wallet, or any other reliable <i>wallet</i>.</li>
                        <li>Enable two-factor authentication (2FA) whenever possible.</li>
                        <li>Never share your private key (or backups) with anyone.</li>
                    </ul>
                ),
            },
            {
                question: "Can I send Bitcoin to the \"investment\" platform XYZ?",
                answer: (
                    <p>
                        No, it is almost certainly a scam. <b>NO MATTER HOW CREDIBLE THE SITE MAY SEEM, NEVER</b> send Bitcoin or other virtual assets to investment platforms without doing the minimum amount of research first.
                    </p>
                ),
            },
        ],
    },

    {
        category: "Registration",
        questions: [
            {
                question: "What documents are needed for registration?",
                answer: (
                    <p>
                        No documents are required; you only need to authenticate on our <a href="/registo" target="_blank" rel="noreferrer">portal</a> with your Digital Mobile Key (DMK).
                    </p>
                ),
            },
            {
                question: "How long does registration approval take?",
                answer: (
                    <p>
                        Registration is approved within a maximum period of 2 hours.
                    </p>
                ),
            },
            {
                question: "How can I obtain my Digital Mobile Key (DMK)?",
                answer: (
                    <>
                        <p>
                            The Digital Mobile Key (DMK) is a simple and secure authentication method used by various services. Follow the steps below to obtain your DMK:
                        </p>
                        <ol>
                            <li>
                                Access the official website at <a href="https://www.autenticacao.gov.pt" target="_blank" rel="noreferrer">autenticacao.gov.pt</a>.
                            </li>
                            <li>Select the option <b>Activate Digital Mobile Key</b>.</li>
                            <li>
                                Choose one of the activation methods presented.
                            </li>
                            <li>Set your mobile number and the DMK PIN.</li>
                            <li>Confirm the process with the code sent to your mobile phone.</li>
                        </ol>
                        <p>
                            For more information, visit the official website:{" "}
                            <a href="https://www.autenticacao.gov.pt" target="_blank" rel="noreferrer">
                                autenticacao.gov.pt
                            </a>.
                        </p>
                    </>
                ),
            },
        ],
    },


    {
        category: "Other",
        questions: [
            {
                question: "Can I buy a fraction of virtual currency?",
                answer: "Yes, virtually all virtual currencies allow you to purchase fractions.",
            },
            {
                question: "I lost access to my wallet where I stored the virtual currency I bought at the terminal. Can you help me?",
                answer: (
                    <span>
                        Unfortunately, there is nothing we can do to help you as we have no control over transactions already published on the blockchain.
                    </span>
                ),
            },
            {
                question: "When will Mind the Coin support virtual currency X?",
                answer: (
                    <p>
                        Mind the Coin only operates with virtual currencies of high reputation and an active developer community. If the desired currency meets these requirements, send an email to <a href="mailto:suporte@mindthecoin.com">suporte@mindthecoin.com</a>
                        &nbsp; with your request.
                    </p>
                ),
            },
        ],
    },

];

export default faqs;
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import './Informacoes.css';

const Informacoes = () => {
  const { t } = useTranslation('informacoes');

  return (
      <section className='container'>
        <div className="row">
          <div className="col s12 m9 l10">
              <div id="introduction" className="section scrollspy">
                <h1 className="yellow-text text-darken-2">{t('informacoes.title')}</h1>
                <p>{t('informacoes.introduction')}</p>

                <video className="responsive-video full-width-video" controls>
                  <source src="/assets/videos/what_is_bitcoin.mp4" type="video/mp4" />
                  {t('informacoes.video_not_supported')}
                </video>

                <div className="text-block">
                  <h5>
                    <i className="yellow-text text-darken-2 fas fa-info-circle"></i>{' '}
                    {t('informacoes.section1_title')}
                  </h5>
                  <p>
                    <Trans t={t}
                      i18nKey="informacoes.section1_p1"
                      components={{ b: <b /> }}
                    />

                  </p>
                  <p>
                    <Trans t={t}
                      i18nKey="informacoes.section1_p2"
                      components={{ b: <b /> }}
                    />
                  </p>
                  <p>
                    <Trans t={t}
                      i18nKey="informacoes.section1_p3"
                      components={{ b: <b />, u: <u /> }}
                    />
                  </p>
                  <p>
                    <Trans t={t}
                      i18nKey="informacoes.section1_p4"
                      components={{ b: <b />, u: <u /> }}
                    />
                  </p>
                  <p>
                    <Trans t={t}
                      i18nKey="informacoes.section1_p5"
                      components={{ b: <b />, u: <u /> }}
                    />
                  </p>
                </div>

                <div className="text-block">
                  <h5>
                    <i className="yellow-text text-darken-2 fas fa-graduation-cap"></i>{' '}
                    {t('informacoes.section2_title')}
                  </h5>
                  <p>{t('informacoes.section2_intro')}</p>

                  <h6><b>{t('informacoes.official_websites')}</b></h6>
                  <ul>
                    <li>
                      <Trans t={t}
                        i18nKey="informacoes.bitcoin_org"
                        components={{
                          a: <a href="https://bitcoin.org" target="_blank" rel="noreferrer" aria-label="Bitcoin Website"
                          />
                        }} />
                    </li>
                    <li>
                      <Trans t={t}
                        i18nKey="informacoes.litecoin_org"
                        components={{ a: <a href="https://litecoin.org" target="_blank" rel="noreferrer" aria-label="Litecoin Website" /> }} />
                    </li>
                  </ul>

                  <h6><b>{t('informacoes.recommended_books')}</b></h6>
                  <ul>
                    <li><i>{t('informacoes.book1')}</i> - Andreas M. Antonopoulos</li>
                    <li><i>{t('informacoes.book2')}</i> - Saifedean Ammous</li>
                    <li><i>{t('informacoes.book3')}</i> - Jason A. Williams</li>
                  </ul>

                  <h6><b>{t('informacoes.technical_documents')}</b></h6>
                  <ul>
                    <li><a target="_blank" href="/assets/docs/bitcoin.pdf" rel="noreferrer"><i>Bitcoin: A Peer-to-Peer Electronic Cash System</i> - Satoshi Nakamoto</a></li>
                  </ul>

                  <h6><b>{t('informacoes.educational_videos')}</b></h6>
                  <ul>
                    <li><a target="_blank" href="https://www.youtube.com/watch?v=bBC-nXj3Ng4" rel="noreferrer">How Bitcoin Works Under the Hood</a></li>
                    <li><a target="_blank" href="https://www.youtube.com/watch?v=l1si5ZWLgy0&list=PLPQwGV1aLnTuN6kdNWlElfr2tzigB9Nnj" rel="noreferrer">Introduction to Bitcoin by Andreas M. Antonopoulos</a></li>
                    <li><a target="_blank" href="https://www.youtube.com/watch?v=SSo_EIwHSd4" rel="noreferrer">Bitcoin Explained Simply</a></li>
                  </ul>


                  <h6><b>{t('informacoes.block_explorers')}</b></h6>
                  <ul>
                    <li><a target="_blank" href="https://blockchair.com" rel="noreferrer">Blockchair</a> - {t('informacoes.blockchair')} </li>
                    <li><a target="_blank" href="https://mempool.space/" rel="noreferrer">mempool.space</a> - {t('informacoes.mempool')}</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
      </section >
  );
};

export default Informacoes;
import { useEffect } from 'react';

const Termos = () => {
    useEffect(() => {
      window.location.href = "/assets/docs/termos.pdf";
    }, []);
  
    return null; // Render nothing while redirecting
  };

export default Termos;

import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import HomePage from './pages/Index';
import Faq from './pages/Faq';
import Informacoes from './pages/Informacoes';
import Instrucoes from './pages/Instrucoes';
import InstrucoesCarteira from './pages/InstrucoesCarteira';
import Privacidade from './pages/Privacidade';
import Termos from './pages/Termos';
import Terms from './pages/Terms';
import CryptoTools from './pages/CryptoTools';

import Cookies from './pages/Cookies';
import SEO from "./components/Seo";

import Navbar from './components/Navbar';
import Footer from './components/Footer';

import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';

const ExternalRedirect = ({ url }) => {
    useEffect(() => {
        window.location.href = url;
    }, [url]);

    return null; // No need to render anything
};

const App = () => {

    const { i18n } = useTranslation();

    return (
        <Router>
            <SEO/>
            <Navbar />
            <div className="content">
                <Routes>
                    <Route path="/" element={<Navigate replace to={`/${i18n.resolvedLanguage}`} />} />

                    <Route path="/:lang/" element={<HomePage />} />
                    <Route path="/:lang/faq" element={<Faq />} />
                    <Route path="/:lang/informacoes" element={<Informacoes />} />
                    <Route path="/:lang/informations" element={<Informacoes />} />
                    <Route path="/:lang/instrucoes" element={<Instrucoes />} />
                    <Route path="/:lang/instructions" element={<Instrucoes />} />
                    <Route path="/:lang/instructions/wallet" element={<InstrucoesCarteira />} />
                    <Route path="/:lang/instrucoes/carteira" element={<InstrucoesCarteira />} />
                    <Route path="/:lang/privacidade" element={<Privacidade />} />
                    <Route path="/:lang/privacy" element={<Privacidade />} />
                    <Route path="/:lang/termos" element={<Termos />} />
                    <Route path="/:lang/terms" element={<Terms />} />
                    <Route path="/:lang/cookies" element={<Cookies />} />
                    <Route path="/:lang/tools" element={<CryptoTools />} />

                    {/* External Redirects */}
                    <Route path="/registo" element={<ExternalRedirect url="https://registo.mindthecoin.com" />} />
                    <Route path="/register" element={<ExternalRedirect url="https://registo.mindthecoin.com" />} />

                </Routes>
            </div>
            <Footer />
        </Router>
    );
};

export default App;
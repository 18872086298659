import React from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

const SEO = () => {

    const { i18n } = useTranslation();
    const isEnglish = i18n.resolvedLanguage === 'en';
    const lang = i18n.resolvedLanguage;

    const currentPath = window.location.pathname;
    const cleanPath = currentPath.endsWith('/') && currentPath !== '/'
        ? currentPath.slice(0, -1)
        : currentPath;

    const location = useLocation();
    const url = window.location.origin;
    const canonicalUrl = `${url}${currentPath}`;

    const mainDescriptionEnglish = "Find Bitcoin ATMs and buy Bitcoin, Litecoin and other crypto instantly in Portugal with cash and card.";
    const mainDescriptionPortuguese = "Encontre Bitcoin ATMs e compre Bitcoin, Litecoin e outras criptomoedas instantaneamente em Portugal com numerário ou com cartão multibanco.";

    // Dynamic Meta Descriptions
    const descriptions = {
        "/": isEnglish
            ? mainDescriptionEnglish
            : mainDescriptionPortuguese,

        "/en/": mainDescriptionEnglish,
        "/pt/": mainDescriptionPortuguese,

        "/en/faq": "Frequently Asked Questions about Bitcoin and crypto purchases.",
        "/en/instructions": "Learn how to buy Bitcoin using Bitcoin ATMs from Mind the Coin.",
        "/en/informations": "Learn more about Crytocurrencies with Mind the Coin.",

        "/pt/faq": "Perguntas frequentes sobre Bitcoin e compras de criptomoedas.",
        "/pt/instrucoes": "Aprenda a comprar Bitcoin usando as Bitcoin ATMs da Mind the Coin.",
        "/pt/informacoes": "Saiba mais sobre Criptomoedas com a Mind the Coin.",
    };

    const alternatePaths = {
        "/": isEnglish ? "/en" : "/pt",
        "/en": "/pt",
        "/pt": "/en",
        "/en/informations": "/pt/informacoes",
        "/en/informacoes": "/pt/informacoes",
        "/en/instructions": "/pt/instrucoes",
        "/en/instrucoes": "/pt/instrucoes",
        "/en/faq": "/pt/faq",
        "/en/privacy": "/pt/privacidade",
        "/en/terms": "/pt/termos",
        "/en/cookies": "/pt/cookies",
        "/pt/informacoes": "/en/informations",
        "/pt/informations": "/en/informations",
        "/pt/instrucoes": "/en/instructions",
        "/pt/instructions": "/en/instructions",
        "/pt/faq": "/en/faq",
        "/pt/privacidade": "/en/privacy",
        "/pt/termos": "/en/terms",
        "/pt/cookies": "/en/cookies",
    };

    const alternateLang = isEnglish ? "pt" : "en";

    const metaDescription = descriptions[location.pathname] || descriptions["/"];

    return (
        <Helmet>
            {/* Dynamic Page Title */}
            < title > {isEnglish ? "Mind the Coin - Buy Bitcoin in Portugal" : "Mind the Coin - Compre Bitcoin em Portugal"}</title>

            < link rel="canonical" href={canonicalUrl} />

            <meta name="description" content={metaDescription} />

            <meta property="og:title" content={isEnglish ? "Mind the Coin - Buy Bitcoin in Portugal" : "Mind the Coin - Comprar Bitcoin em Portugal"} />
            <meta property="og:description" content={metaDescription} />
            <meta property="og:url" content={canonicalUrl} />

            <link rel="alternate" hrefLang={alternateLang} href={`${url}${alternatePaths[cleanPath]}`} />
            <link rel="alternate" hrefLang={lang} href={`${url}${cleanPath}`} />

            <link rel="icon" href={`${url}/assets/images/favicon.png`} type="image/png" />

            <script type="application/ld+json">{JSON.stringify({
                "@context": "https://schema.org",
                "@type": "Organization",
                "name": "Mind the Coin",
                "url": "https://mindthecoin.com",
                "logo": `${url}/assets/images/logo.png`,
                "description": "Encontre e utilize Bitcoin ATMs em Portugal. Compre Bitcoin, Litecoin e outras criptomoedas instantaneamente com numerário e cartão multibanco.",
                "foundingDate": "2018",
                "sameAs": [
                    "https://www.facebook.com/mindthecoin",
                    "https://x.com/mindthecoin",
                    "https://www.instagram.com/mindthecoin"
                ]
            })}</script>
        </Helmet >
    );
};

export default SEO;
import React from 'react';
import { useTranslation } from 'react-i18next';

const Privacidade = () => {
  const { t } = useTranslation('privacidade');

  return (
    <>
      <div className="row">
        <div className="col s12 m9 l10">
          <div className="container">
            <div className="text-block">
              <h2>{t('title')}</h2>

              <h3>{t('section1_title')}</h3>

              <p>{t('section1_p1')}</p>

              <p>{t('section1_p2')}</p>

              <p>{t('section1_p3')}</p>

              <p>{t('section1_p4')}</p>

              <h3>{t('section2_title')}</h3>

              <p>{t('section2_p1')}</p>

              <p>{t('section2_p2')}</p>

              <h3>{t('section3_title')}</h3>

              <p>{t('section3_p1')}</p>

              <h3>{t('section4_title')}</h3>

              <p>{t('section4_p1')}</p>

              <h3>{t('section5_title')}</h3>

              <p>{t('section5_p1')}</p>

              <h3>{t('section6_title')}</h3>

              <p>{t('section6_p1')}</p>

              <h3>{t('section7_title')}</h3>

              <p>{t('section7_p1')}</p>

              <h3>{t('section8_title')}</h3>

              <p>{t('section8_p1')}</p>

              <p>{t('section8_p2')}</p>

              <h3>{t('section9_title')}</h3>

              <p>{t('section9_p1')}</p>

              <address>
                Guimarães & Matosa, Lda<br />
                Avenida Barros e Soares, E. N. 101, Nº 423<br />
                4715-213<br />
                Braga
              </address>
            </div>
          </div>
        </div>
      </div>

    </>
  );
};

export default Privacidade;
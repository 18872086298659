import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import './MempoolCalc.css';
import DateInput from './DateInput';

const MempoolCalculator = () => {
  const [btc, setBtc] = useState('1');
  const [sats, setSats] = useState('100000000');
  const [eur, setEur] = useState('0');
  const [btcPrice, setBtcPrice] = useState(null);
  const [error, setError] = useState(null);
  const [initialLoad, setInitialLoad] = useState(true);
  
  const fetchHistoricalPrice = useCallback(async (date) => {
    try {
      const timestamp = Math.floor(date.getTime() / 1000); // Convert date to Unix timestamp
      const response = await axios.get(`https://mempool.space/api/v1/historical-price?currency=EUR&timestamp=${timestamp}`);
      const historicalPrice = response.data.prices[0]; // Adjust based on the actual API response structure
      // console.log(historicalPrice)
      if (historicalPrice && historicalPrice.EUR) {
        setBtcPrice(historicalPrice.EUR);
        setEur((1 * historicalPrice.EUR).toFixed(2)); // Update EUR value based on the fetched price
        setBtc(1); // Reset BTC value to 1
        setSats((1 * 100000000).toFixed(0)); // Reset sats value to 100000000
      } else {
        setError('Failed to fetch historical price.');
      }
    } catch (err) {
      setError('An error occurred while fetching the Bitcoin price.');
    }
  }, []);
  
  
  useEffect(() => {
    if (initialLoad) {
      fetchHistoricalPrice(new Date());
      setInitialLoad(false);
    }
  }, [fetchHistoricalPrice, initialLoad]);

  const handleFocus = () => {
    document.querySelectorAll('.input-field label').forEach(label => {
      label.classList.add('active');
    });
  };

  const handleBlur = () => {
    if (!btc && !sats && !eur) {
      document.querySelectorAll('.input-field label').forEach(label => {
        label.classList.remove('active');
      });
    }
  };

  const handleBtcChange = (e) => {
    const btcValue = e.target.value;
    setBtc(btcValue);
    const satsValue = (btcValue * 100000000).toFixed(0);
    setSats(satsValue);
    if (btcPrice !== null && !isNaN(parseFloat(btcValue))) {
      const eurValue = (parseFloat(btcValue) * btcPrice).toFixed(2);
      setEur(eurValue);
    }
  };

  const handleEurChange = (e) => {
    const eurValue = e.target.value;
    setEur(eurValue);
    if (btcPrice !== null && !isNaN(parseFloat(eurValue))) {
      const btcValue = (parseFloat(eurValue) / btcPrice).toFixed(8);
      setBtc(btcValue);
      const satsValue = (btcValue * 100000000).toFixed(0);
      setSats(satsValue);
    }
  };


  return (
    <div className="">
      <h1 className='primary-text'>Ferramentas</h1>
      <h2 className="primary-text">Calculadora</h2>
      {error && (
        <div className="error">
          <p>{error}</p>
        </div>
      )}
      <div className="row">


      <div className="input-field col s6 m6 l4">
        <label  className='active' htmlFor="btc">Bitcoin (BTC)</label>
        <input
          type="number"
          id="btc"
          value={btc}
          onChange={handleBtcChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
        />
      </div>
        <div className="input-field col s6 m6 l4">
        <label  className='active' htmlFor="eur">Euros (EUR)</label>
        <input
          type="number"
          id="eur"
          value={eur}
          onChange={handleEurChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
        />
      </div>
      <div className='col s6 m6 l4'>
        <DateInput fetchHistoricalPrice={fetchHistoricalPrice} />
      </div>
    </div>
    </div>
  );
};

export default MempoolCalculator;
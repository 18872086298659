import React from 'react';

const Terms = () => {
    return (
        <>
        <div className="row">
            <div className="container">
                <div className="text-block">
                    By purchasing, owning, and using Crypto-Assets, you expressly acknowledge and assume the following risks:
                    <h3>1. Risk of Losing Access to Crypto-Assets Due to Loss of Private Key(s), Custodial Error or Purchaser Error</h3>
                    A private key, or a combination of private keys, is necessary to control and dispose of the Crypto-Assets stored in your digital wallet or vault. Accordingly, loss of requisite private key(s) associated with your digital wallet or vault storing Crypto-Assets will result in loss of such Crypto-Assets. Moreover, any third party that gains access to such private key(s), including by gaining access to login credentials of a hosted wallet service you use, may be able to misappropriate your Crypto-Assets. Any errors or malfunctions caused by or otherwise related to the digital wallet or vault you choose to receive and store Crypto-Assets in, including your own failure to properly maintain or use such digital wallet or vault, may also result in the loss of your Crypto-Assets. Additionally, your failure to follow precisely the procedures set forth in for buying and receiving Crypto-Assets, including, for instance, if you provide the wrong address for the Crypto-Assets Receipt Address, or provides an address that is not compatible, may result in the loss of your Crypto-Assets.


<h3>2. Risks Associated with the used Protocols</h3>
Any malfunction, breakdown or abandonment of the used protocols may have a material adverse effect on the Crypto-Assets. Moreover, advances in cryptography, or technical advances such as the development of quantum computing, could present risks to the Crypto-Assets, by rendering ineffective the cryptographic consensus mechanism that underpins the protocol.


<h3>3. Risk of Mining Attacks</h3>
The Crypto-Assets are susceptible to attacks by miners in the course of validating Crypto-Assets transactions on the blockchain, including, but not limited, to double-spend attacks, majority mining power attacks, and selfish-mining attacks. Any successful attacks present a risk to the Crypto-Assets, including, but not limited to, accurate execution and recording of transactions involving Crypto-Assets.


<h3>4. Risk of Hacking and Security Weaknesses</h3>
Hackers or other malicious groups or organizations may attempt to interfere with the Crypto-Assets in a variety of ways, including, but not limited to, malware attacks, denial of service attacks, consensus-based attacks, Sybil attacks, smurfing and spoofing.


<h3>5. Risks Associated with Markets for Crypto-Assets</h3>
The Company will not support or otherwise facilitate any secondary trading or external valuation of Crypto-Assets. This could therefore create illiquidity risk with respect to the Crypto-Assets you own. Even if secondary trading of Crypto-Assets is facilitated by third party exchanges, such exchanges may be relatively new and subject to little or no regulatory oversight, making them more susceptible to fraud or manipulation. Furthermore, to the extent that third-parties do ascribe an external exchange value to Crypto-Assets (e.g., as denominated in a digital or fiat currency), such value may be extremely volatile and diminish to zero.


<h3>6. Risk of Uninsured Losses</h3>
Unlike bank accounts or accounts at some other financial institutions, Crypto-Assets are uninsured unless you specifically obtain private insurance to insure them. Thus, in the event of loss or loss of utility value, there is no public insurer, or private insurance to offer recourse to you.


<h3>7. Risks Associated with Uncertain Regulations and Enforcement Actions</h3>
The regulatory status of the Crypto-Assets and distributed ledger technology is unclear or unsettled in many jurisdictions. It is difficult to predict how or whether regulatory agencies may apply existing regulation with respect to such technology and its applications, including the Crypto-Assets. It is likewise difficult to predict how or whether legislatures or regulatory agencies may implement changes to law and regulation affecting distributed ledger technology and its applications, including the Crypto-Assets. Regulatory actions could negatively impact Crypto-Assets in various ways, including, for purposes of illustration only, through a determination that the purchase, sale and delivery of the Crypto-Assets constitutes unlawful activity or that the Crypto-Assets are a regulated instrument that require registration or licensing of those instruments or some or all of the parties involved in the purchase, sale and delivery thereof. The Company may cease operations in a jurisdiction in the event that regulatory actions, or changes to law or regulation, make it illegal to operate in such jurisdiction, or commercially undesirable to obtain the necessary regulatory approval(s) to operate in such jurisdiction.

<h3>8. Risks Arising from Taxation</h3>
The tax characterisation of Crypto-Assets is uncertain. You must seek your own tax advice in connection with purchasing Crypto-Assets, which may result in adverse tax consequences to you, including withholding taxes, income taxes and tax reporting requirements.


<h3>9. Risk of an Unfavourable Fluctuation of the Crypto-Assets</h3>
In addition to the usual market forces, there are several potential events which could exacerbate the risk of unfavourable fluctuation in the value of ETH, BTC, and other Crypto-Assets, including uncertainties created by the lack of resolution to the bitcoin scaling debate, the possibility of a so-called “Hard Fork” of bitcoin if one of the competing camps in the scaling debate decides to force the issue; another DAO-like attack on the Ethereum network; or significant security incidents or market irregularities at one or more of the major cryptocurrency exchanges.

<h3>10. Unanticipated Risks</h3>
Cryptographic tokens such as the Crypto-Assets are a new and untested technology. In addition to the risks described above there are other risks associated with your purchase, possession and use of the Crypto-Assets, including unanticipated risks. Such risks may further materialize as unanticipated variations or combinations of the risks described.

</div>
</div>
</div>

        </>
    );
};

export default Terms;

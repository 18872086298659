import React from 'react';
import locations_pt from '../../assets/js/locations_pt';
import locations_en from '../../assets/js/locations_en';
import StatusIndicator from '../../utils/StatusIndicator';
import { useTranslation } from 'react-i18next';

const LocationText = () => {
    const { t } = useTranslation('location');
    const { i18n } = useTranslation();
    const isEnglish = i18n.language === 'en';
    let locations;

    if (isEnglish) { locations = locations_en; } else { locations = locations_pt; }

    return (
        <>
            <div id="locationText" className="location-text-horizontal">
                {locations.map((location) => (
                    <div key={location.id} className="location-item">
                        <p>
                            <b>{location.city}</b>
                            <StatusIndicator status={location.status} />
                            <br />
                            {location.location}
                        </p>

                        <p>
                            {location.address.join(', ')}
                        </p>



                        <p>
                            {/* Render opening times dynamically */}
                            {Array.isArray(location.openingTimes) ? (
                                location.openingTimes.map((time, index) => (
                                    <React.Fragment key={index}>
                                        <i>{time}</i>
                                        {index < location.openingTimes.length - 1 && <br />} {/* Add line breaks between times */}
                                    </React.Fragment>
                                ))
                            ) : (
                                <i>{location.openingTimes}</i> // Render as-is if it's a string
                            )}
                            <br />
                            {/* External links */}
                            <a target="_blank" href={location.infoLink} rel="noopener noreferrer">
                                {t('location.more_information')}
                            </a>{' '}
                            |{' '}
                            <a
                                target="_blank"
                                href={`https://www.google.com/maps/search/?api=1&map_action=map&query=${location.pin[0]}%2C${location.pin[1]}`}
                                rel="noopener noreferrer"
                            >
                                {t('location.map')}
                            </a>
                        </p>
                    </div>
                ))}
            </div>
        </>
    );
};

export default LocationText;
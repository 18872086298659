const StatusIndicator = ({ status }) => {
    if (status === 'online') {
        return (
            <span className="status-indicator online">
                Online
            </span>
        );
    }

    if (status === 'offline') {
        return (
            <span className="status-indicator offline">
                Offline
            </span>
        );
    }

    return null; // Fallback if the status is neither "online" nor "offline"
};

export default StatusIndicator;


import React from 'react';
import './Warning.css';
import { useTranslation } from 'react-i18next';



const Warning = () => {

  const { t } = useTranslation('warning'); 

    return (
        <>
        <section>
        <div className="alert">
            {/* <span
            className="closebtn"
            onClick={(e) => {
              e.target.parentElement.style.display = 'none';
            }}
          >
            &times;
          </span> */}

            <p>
            <strong>{t('warning.title')}</strong><br />
            {t('warning.message')}
            </p>
          </div>
        </section>
        </>
    );
};

export default Warning;

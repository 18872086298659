import React, { useEffect } from 'react'
import './Navbar.css'
import LanguageSwitcher from './LanguageSwitcher'
import { useTranslation } from 'react-i18next'
import { Link, useParams } from 'react-router-dom'

const Navbar = () => {
  const { t, i18n } = useTranslation('navbar')
  const { lang } = useParams()
  const currentLang = lang || i18n.resolvedLanguage // Default to detected language

  useEffect(() => {
    const M = window.M

    // Initialize the sidenav(s)
    const elemsSideNav = document.querySelectorAll('.sidenav')
    const sideNavInstances = M.Sidenav.init(elemsSideNav)

    // Attach click event to each link in the sidenav
    const navLinks = document.querySelectorAll('.sidenav li')
    navLinks.forEach((link) => {
      link.addEventListener('click', () => {
        // Close all side nav instances
        sideNavInstances.forEach((instance) => {
          instance.close()
        })
      })
    })
  }, [])

  return (
    <>
      <nav id='topnavbar'>
        <div className='nav-wrapper container'>
          <Link
            id='logo-container'
            to={`/${currentLang}`}
            className='brand-logo'
          >
            <img
              className='navbar-logo'
              src='/assets/images/logo-dark-bg.png'
              alt='Mind the Coin'
            />
          </Link>

          {/* Burger Icon for Mobile */}
          {/* eslint-disable-next-line */}
          <a
            href='#'
            data-target='nav-mobile'
            className='sidenav-trigger'
            rel='noreferrer'
          >
            <i className='fas fa-bars'></i>
          </a>

          {/* Desktop Navigation */}
          <ul className='right hide-on-med-and-down'>
            <li>
              <Link to={`/${currentLang}`}>{t('navbar.home')}</Link>
            </li>
            <li>
              <Link to={currentLang === 'en' ? `/register` : `/registo`}>
                {t('navbar.register')}
              </Link>
            </li>
            <li>
              <Link
                to={
                  currentLang === 'en'
                    ? `/${currentLang}/informations`
                    : `/${currentLang}/informacoes`
                }
              >
                {t('navbar.informations')}
              </Link>
            </li>
            <li>
              <Link
                to={
                  currentLang === 'en'
                    ? `/${currentLang}/instructions`
                    : `/${currentLang}/instrucoes`
                }
              >
                {t('navbar.instructions')}
              </Link>
            </li>
            <li>
              <Link to={`/${currentLang}/faq`}>{t('navbar.faq')}</Link>
            </li>
            {/* <li>
              <a href="https://medium.com/@mindthecoin" target="_blank" rel="noreferrer">
                {t('navbar.blog')}
              </a>
            </li> */}
            <li>
              {/* Language Switcher */}
              <LanguageSwitcher />
            </li>
          </ul>

          {/* Mobile Navigation */}
          <ul id='nav-mobile' className='sidenav grey darken-3'>
            <li>
              <Link to={`/${currentLang}`} className='white-text'>
                <img
                  className='navbar-logo'
                  src='/assets/images/logo-dark-bg.png'
                  alt='Mind the Coin'
                  style={{ paddingTop: '2rem' }}
                />
              </Link>
            </li>
            <li className='first'>
              <Link className='white-text sidenav-close' to={'/'}>
                {t('navbar.home')}
              </Link>
            </li>
            <li>
              <Link
                className='white-text sidenav-close'
                to={currentLang === 'en' ? `/register` : `/registo`}
              >
                {t('navbar.register')}
              </Link>
            </li>
            <li>
              <Link
                className='white-text sidenav-close'
                to={
                  currentLang === 'en'
                    ? `/${currentLang}/informations`
                    : `/${currentLang}/informacoes`
                }
              >
                {t('navbar.informations')}
              </Link>
            </li>
            <li>
              <Link
                className='white-text sidenav-close'
                to={
                  currentLang === 'en'
                    ? `/${currentLang}/instructions`
                    : `/${currentLang}/instrucoes`
                }
              >
                {t('navbar.instructions')}
              </Link>
            </li>
            <li className='last'>
              <Link className='white-text sidenav-close' to={`/${currentLang}/faq`}>
                {t('navbar.faq')}
              </Link>
            </li>
            {/* <li>
              <a className="white-text" href="https://medium.com/@mindthecoin" rel="noreferrer" target="_blank">
                {t('navbar.blog')}
              </a>
            </li> */}
            <li className='language-mobile'>
              <LanguageSwitcher />
            </li>
          </ul>
        </div>
      </nav>
    </>
  )
}

export default Navbar
